import CryptoJS from 'crypto-js'

// AES-128-CBC加密
// 默认自定义的约定密钥(与后端密钥保持一致)
let key = CryptoJS.enc.Utf8.parse(format('DHYR1VDHSTM8Q3QF')) //  内容可自定义  16位长度字符
let iv = CryptoJS.enc.Utf8.parse(format('0000100100001001')) //  密钥偏移量    16位长度字符

/**
 * AES对称加密 （CBC模式，需要偏移量）
 * @param {Object} params 明文参数
 */
export const encrypt = str => {
  // 明文
  let srcs = CryptoJS.enc.Utf8.parse(str)
  console.log('明文', srcs)
  // 加密
  let encrypt = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC, // AES加密模式
    padding: CryptoJS.pad.Pkcs7, // 填充方式，这里后端是5 也可以用
  })
  console.log('加密后', encrypt)
  console.log('base64加密', CryptoJS.enc.Base64.stringify(encrypt.ciphertext))
  return CryptoJS.enc.Base64.stringify(encrypt.ciphertext) // 返回base64格式密文
}

function format(key) {
  if (key.length > 16) return key.slice(0, 16)
  return key
}
